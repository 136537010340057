html {
  font-size: 20px;
}

body {
  min-height: 100vh;
  margin: 0;
  background: #111;
  color: #fff;

  font-family: "Roboto", "Arial", sans-serif;

  font-size: 0.75rem;
}

button {
  background: none;
  border: none;
  outline: none;
  cursor: pointer;
  color: inherit;
  font-size: inherit;

  &:disabled {
    filter: brightness(0.6);
    cursor: not-allowed;
  }
}

a {
  color: inherit;
  text-decoration: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
